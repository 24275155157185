@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('http://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&dsiplay=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;0,1000;1,600&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Saira:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Saira', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  @font-face {
    font-family: gilroy-bold;
    src: url(./assets/Fonts/gilroy/Gilroy-Bold.ttf);
  }

  @font-face {
    font-family: gilroy-light;
    src: url(./assets/Fonts/gilroy/Gilroy-Light.ttf);
  }

  @font-face {
    font-family: gilroy-medium;
    src: url(./assets/Fonts/gilroy/Gilroy-Medium.ttf);
  }

  @font-face {
    font-family: gilroy-regular;
    src: url(./assets/Fonts/gilroy/Gilroy-Regular.ttf);
  }

  @font-face {
    font-family: gilroy-semibold;
    src: url(./assets/Fonts/gilroy/Gilroy-SemiBold.ttf);
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .cardHeader {
    padding: 18px 20px;
    border-bottom: 1px solid #e9ebec;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


