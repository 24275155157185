.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#bee-plugin-container {
  height: 100%;
}

.top-bar {
  border: 2px solid red !important;
}

#externalSystemContainer {
  background-color: darkgrey;
  padding: 5px 0 5px 20px;
}
#undoButton,
#redoButton {
  display: none;
}
#stripoSettingsContainer {
  width: 450px;
  float: left;
  background-color: #ededed;
  padding: 0 20px;
}
#stripoPreviewContainer {
  width: calc(100% - 550px);
  float: left;
  background-color: #f7f7f7 !important;
  height: 785px !important;
  padding-top: 28px !important;
  overflow: auto;
}

#stripoPreviewContainer .esdev-preview-splitter {
  height: 785px !important;
  background-color: #f7f7f7 !important;
}

.esdev-app iframe.stripo-preview-frame {
  height: 75% !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray; 
}

/* 
#stripoPreviewContainer .esdev-preview-splitter iframe {
  height: 80vh !important;
} */
/* 
#stripoPreviewContainer .esdev-preview-splitter {
  height: auto !important;
} */
.notification-zone {
  position: fixed;
  width: 400px;
  z-index: 99999;
  right: 20px;
  bottom: 80px;
}
.control-button {
  border-radius: 17px;
  padding: 5px 10px;
  border-color: grey;
}
#changeHistoryLink {
  cursor: pointer;
}
#externalPreviewPopup {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  font-family: sans-serif;
}

#externalPreviewPopup .modal-container {
  background-color: #f6f6f6;
  border-radius: 17px 17px 30px 30px;
  width: 1120px;
  margin: 10px auto;
}

#externalPreviewPopup .modal-header-container {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

#externalPreviewPopup .modal-title {
  margin: 0;
  font-size: 18px;
  color: rgb(85, 85, 85);
}

#externalPreviewPopup .modal-close-button {
  cursor: pointer;
  background: transparent;
  border: 0;
  float: right;
  font-size: 21px;
  font-weight: bold;
  opacity: 0.2;
}

#externalPreviewPopup .preview-container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#externalPreviewPopup .preview-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

#externalPreviewPopup .preview-col-sm-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

#externalPreviewPopup .preview-col-sm-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

#externalPreviewPopup .preview-col-sm-4,
.preview-col-sm-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

#externalPreviewPopup .esdev-desktop-device-screen,
#externalPreviewPopup .esdev-mobile-device-screen {
  background-color: #ffffff;
}

#externalPreviewPopup .esdev-desktop-device {
  background: #ffffff;
  border: 2px solid #e8e8e8;
  font-size: 0;
  position: relative;
  border-radius: 10px 10px 3px 3px;
}

#externalPreviewPopup .esdev-desktop-device:before {
  content: '―–';
  display: block;
  background: #e8e8e8;
  position: absolute;
  margin-top: 16px;
  border-radius: 10px;
  margin-left: 47px;
  font-size: 73px;
  line-height: 0;
  font-family: sans-serif;
}

#externalPreviewPopup .esdev-email-window-panel {
  padding: 15px 15px 12px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
}

#externalPreviewPopup .esdev-email-user-avatar {
  float: left;
  color: #e8e8e8 !important;
  text-shadow: none !important;
  font-size: 16px !important;
}

#externalPreviewPopup .esdev-email-subject {
  padding-left: 13px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#externalPreviewPopup .esdev-mobile-device {
  position: relative;
  height: 698px;
  width: 344px;
  border-radius: 40px;
  padding: 50px 10px 60px;
  background: #fff;
  border: 2px solid #e8e8e8;
}

#externalPreviewPopup .esdev-mobile-device-screen {
  box-shadow: 0 0 0 1px #e8e8e8;
  border-radius: 2px;
  overflow: hidden;
  height: 568px;
}

#externalPreviewPopup .esdev-mobile-device-screen .esdev-mail-bottom-bar {
  margin-top: -5px;
}

#externalPreviewPopup .esdev-mobile-device:after {
  position: absolute;
  bottom: 13px;
  left: 50%;
  margin-left: -24px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  border: 2px solid #e8e8e8;
  content: ' ';
  display: block;
}

#externalPreviewPopup .esdev-mobile-device:before {
  position: absolute;
  top: 22px;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  background: #e8e8e8;
  height: 8px;
  border-radius: 20px;
  content: ' ';
  display: block;
}

.collapse {
  visibility: unset;
}
#stripoSettingsContainer .esdev-settings-panel-view .sripo-watermark {
  display: none !important;
}
#stripoSettingsContainer .nav {
  border: 1px solid rgb(223, 223, 223);
}
#stripoSettingsContainer .nav a {
  border: 0px;
}

#stripoSettingsContainer .nav .active a {
  background-color: #3575d5;
  color: white;
}

#stripoSettingsContainer .esdev-block-wrapper .esdev-block:hover{
  border : 1px solid #3575d5;
  box-shadow: 0 0 0 1px #3575d5;
}

#stripoSettingsContainer .esdev-app .esdev-option-panel .panel {
  background-color: #61dafb;
}

#stripoPreviewContainer .stripo-preview-frame .esd-email-paddings {
padding-top: 0px !important;
}

.esd-email-paddings {
  padding-top: 0;
}
